<template>
  <div class='page faq'>
    <div class='banner'>
      <img src='https://cdn.s777.club/web/faq-banner.png'/>
      <h1>FAQs</h1>
    </div>
    <div class='content'>
      <h2>Frequently Asked Questions</h2>
      
      <div class='question'>
        <h4 class='faq-accordion-header' @click='toggle($event)'>How do I create a Super777 Club Casino account?</h4>
        <div class='faq-accordion-content-wrapper'>
          <div class='faq-accordion-content'>
            <p>In order to create an account, all you have to do is click on the 'Signup' button located in the top right corner of the webpage (for desktop) on the top left corner of the webpage (for mobile) and then enter your details in the fields provided. Once you have done this, you will receive a confirmation email with a link. You should click on this link in order to verify your account. Once these simple steps have been completed, you’re good to go!</p>
          </div>
        </div>
      </div>
      
      <div class='question'>
        <h4 class='faq-accordion-header' @click='toggle($event)'>What do I do if I have forgotten my password?</h4>
        <div class='faq-accordion-content-wrapper'>
          <div class='faq-accordion-content'>
            <p>Well, it happens to the best of us, but it’s not the end of the world! The fastest way to reset your password is to click on the 'Login' button, located the 'Forgot your password?' link and then click on that. Once you have done this, you will receive an email from us which will take you to another page. Here you will be able to create a new password (preferably one you will remember!).</p>
            <p>Alternatively, you can contact our customer support team via our online chat, or by email to <a href='mailto:admin@s777.club'>admin@s777.club</a>. We’re always here to help!</p>
          </div>
        </div>
      </div>

      <div class='question'>
        <h4 class='faq-accordion-header' @click='toggle($event)'>What payment methods do you accept?</h4>
        <div class='faq-accordion-content-wrapper'>
          <div class='faq-accordion-content'>
            <p>We are currently accepting payment via <strong>Bitcoin (BTC)</strong>. Direct credit / debit cards are not accepted due to the vast number of fraud cases we have encountered in the past. <strong>Cashapp</strong> payments will be coming REAL soon!</p>
            <p>Please follow our FB page for the latest updates: <a href='https://facebook.com/super777club' target='_blank'>@super777club</a></p>
          </div>
        </div>
      </div>
      
      <div class='question'>
        <h4 class='faq-accordion-header' @click='toggle($event)'>How long does it take for the deposit to be credited to my account?</h4>
        <div class='faq-accordion-content-wrapper'>
          <div class='faq-accordion-content'>
            <p>The good news is that you don't have to wait at all! The deposit is credited to your account almost immediately, so you are ready to go as soon as you have topped up!</p>
          </div>
        </div>
      </div>

      <div class='question'>
        <h4 class='faq-accordion-header' @click='toggle($event)'>What are the top up and withdraw requirements & limits?</h4>
        <div class='faq-accordion-content-wrapper'>
          <div class='faq-accordion-content'>
            <p>The minimum you can top-up is 10 USD. The minimum you can withdraw is 50 USD. Our daily withdrawal limit will be capped at 5,000 USD per customer.</p>
          </div>
        </div>
      </div>

      <div class='question'>
        <h4 class='faq-accordion-header' @click='toggle($event)'>How long do I have to wait for my withdrawal to be processed?</h4>
        <div class='faq-accordion-content-wrapper'>
          <div class='faq-accordion-content'>
            <p>Our aim is to process all withdrawals immediately. However, due to our large membership base we will only be able to process all withdrawals once per day. This is to ensure that all members get to withdraw up to the daily limits. Our batch processing timings are as follows:</p>
            <p>9pm to 2am (Eastern Standard Time)</p>
            <p>Please submit your request before 5pm for same day withdrawal processing.</p>
          </div>
        </div>
      </div>

    </div>
  </div>
</template>

<script>
export default {
  name: 'Faq',
  methods:{
    toggle (event) {
      event.target.classList.toggle('active')
      event.target.nextSibling.classList.toggle('active')
    }
  }
}
</script>
